<template>
  <div class="p-index">
    <headers></headers>
    <div  class="bg"></div>
    <div class="content">
      <el-breadcrumb  style="padding: 15px 0" separator="/">
        <el-breadcrumb-item>个人首页</el-breadcrumb-item>
        <el-breadcrumb-item><a>加入已有企业</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <div style="text-align: center;margin-top: 50px">
          <el-input placeholder="搜索默认文本：企业名称/统一社会信用代码" v-model="key"  style="width: 400px;margin-right: 30px"></el-input>
          <el-button type="primary" @click="search">查询</el-button>
        </div>
        <div>
          <el-table
              :data="tableData"
              style="width: 100%;margin-top: 36px;">
            <el-table-column
                prop="name"
                label="企业名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="usci	"
                align="center"
                label="组织机构代码">
            </el-table-column>
            <el-table-column
                prop="contactPerson"
                align="center"
                label="联系人">
            </el-table-column>
            <el-table-column
                prop="contactPhone"
                align="center"
                label="联系电话">
            </el-table-column>
            <el-table-column
                prop="time"
                align="center"
                label="操作" width="200">
              <template  slot-scope="scope">
                <el-button type="primary" size="mini" @click="add(scope)">申请加入该企业</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="fenye">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pageNo"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
    <el-dialog
        title="申请关联企业-申请人信息填写"
        :visible.sync="centerDialogVisible"
        width="800px"
        center>
      <div>
        <el-form ref="form" style="width:800px;margin: 0 auto" :model="form" label-width="180px">
          <el-form-item label="姓名">
            <el-input v-model="form.realName"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="证件类型">
            <el-select v-model="form.personIdType" placeholder="请选择证件类型">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码">
            <el-input v-model="form.personId"></el-input>
          </el-form-item>
          <el-form-item label="职位">
            <el-input v-model="form.post"></el-input>
          </el-form-item>
          <el-form-item label="申请理由">
            <el-input type="textarea" style="width: 500px" v-model="form.reson"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center">
        <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tijiao">确 定</el-button>
      </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import headers from '../../components/headers'
import bottom from "../../components/bottom";
import {enterpriselist, reqrelateenterprise} from "../../request/moudle";

export default {
  name: "qyyd",
  data(){
    return {
      key:'',
      form:{
        realName:'',
        phone:'',
        personIdType:'',
        personId:'',
        post:'',
        reson:''
      },
      enterprise_id:'',
      centerDialogVisible:false,
      pageNo:1,
      total:0,
      tableData:[]
    }
  },
  created() {
    alert(VUE_APP_SERVER_URL)
    this.loadlist()
  },
  methods:{
    search(){
      this.pageNo=1
      this.loadlist()
    },
    loadlist(){
      this.$api.index.enterpriselist({
        current:this.pageNo,
        key:this.key,
        size:50
      }).then((res)=>{
        this.tableData=res.data.records
        this.total=res.data.total
      })
    },
    handleCurrentChange(val) {
      this.pageNo=val
      this.loadlist()
    },
      add(scope){
        this.enterprise_id=scope.row.id
        this.centerDialogVisible=true

      },
    tijiao(){
      this.form.enterpriseId=this.enterprise_id
      this.$api.index.reqrelateenterprise(this.form).then((res)=>{
        this.$message('申请成功')
        this.centerDialogVisible = false
      })
    }
  },
  components: {
    headers, bottom
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-input{
  width: 300px;
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
  .bg{
    width: 100%;
    height: 200px;
    background: url("../../assets/icons/personindex.png") no-repeat;
    background-size: cover;
  }
  .content{
    width: 1200px;
    margin: 0 auto;
    min-height: 1000px;
    .btn{
      text-align: center;
      width: 321px;
      height: 321px;
      margin: 102px;
      background: #fff;
      border-radius: 36px;
    }
  }
</style>